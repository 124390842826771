import React, {Fragment, useContext} from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { CategoryContext } from '@/hooks/useCategories';
import {findBestCategoriesByParentOrder} from '@/utils/categories';
import {TYPE_GABARIT} from '@/utils/enums';
import {path} from '@/utils/path';
import { Auteur, AuteurCompact } from '@/types/auteur';
import { Categorie, CategorieCompact } from '@/types/categorie';
import { Image as ImageType } from '@/types/image';

import Image from '@/ui/image';
import SanitizeHTML from '@/ui/sanitizeHTML';

import styles from './uiPublication.module.scss';
import TagInTo from '@/ui/tagInTo';

import {VARIANT} from '../cartes.constants';

type PropTypes = {
    auteur: Auteur | AuteurCompact,
    categories: Array<Categorie | CategorieCompact>,
    className?: string,
    id: number | string,
    images: Array<ImageType>,
    slug: string,
    titre: string,
    type: string,
    variant?: typeof VARIANT[keyof typeof VARIANT],
};

const UiPublication = ({
    auteur,
    categories = [],
    className = "",
    id,
    images,
    slug,
    titre,
    type,
    variant = VARIANT.COLLECTION,
}: PropTypes) => {
    const url = path.fill(path.ARTICLE, {id, slug});
    const allCategories = useContext(CategoryContext);
    const bestCategories = findBestCategoriesByParentOrder(categories, allCategories);

    if (!url) {
        return null;
    }

    return (
        <div
            data-component="UiPublication"
            className={classNames(styles.me, {
                [className]: className,
                [styles[variant]]: typeof styles[variant] !== 'undefined',
            })}
            key={`UiPublication-${id}`}
        >
            <div className={styles.blockLink}>
                <figure aria-label={titre} className={styles.figure}>
                    <Link href={url} aria-label={titre} className={styles.link}>

                        <Image
                            className={styles.img}
                            images={images}
                            gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                            alt={titre}
                        />

                    </Link>
                </figure>
                <TagInTo className={styles.tag} label={type} />
                <div className={styles.bg}>
                    <div className={styles.inner}>
                        {categories && bestCategories?.length > 0 && (
                            <h3 className={styles.category}>
                                {bestCategories.map(({nom}: Categorie, index: number) => {
                                    if (index !== 0) {
                                        return null;
                                    }

                                    return (
                                        <Fragment key={`bestCategories-${nom}`}>
                                            {index > 0 && <> / </>}
                                            {nom}
                                        </Fragment>
                                    );
                                })}
                            </h3>
                        )}

                        <Link href={url}>
                            <SanitizeHTML tag="h3" className={styles.titleCart} html={titre} />
                        </Link>

                        {auteur && (
                            <span className={styles.auteurContainer}>
                                <Image
                                    alt={auteur.nom}
                                    className={styles.auteur}
                                    images={auteur.images}
                                    gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                />
                                {auteur.nom}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UiPublication;
